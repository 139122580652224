import React from 'react';
import axios from 'axios';
import classNames from 'classnames';
import localization from '../../../localization';
import config from '../../../config';
import { Card, CardContent } from '@mui/material';
import Tooltip from '../../Tooltip';
import { navigate } from 'gatsby-link';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { userSet } from '../../../state/reducers/globalReducer';
import * as style from './index.module.scss';
import Checkbox from '../../Checkbox';

const CreateOrganisation = () => {
    // Redux state
    const language = useAppSelector((state) => state.global.language);
    const accessToken = useAppSelector(
        (state) => state.global.auth.accessToken
    );
    const isSignedIn = useAppSelector((state) => state.global.isSignedIn);
    const dispatch = useAppDispatch();
    const [acceptedTerms, setAcceptedTerms] = React.useState(false);

    // const setUser = useCallback(
    //     (user) =>
    //         dispatch({
    //             type: `USER_SET`,
    //             user,
    //         }),
    //     [dispatch]
    // );

    // Form state
    const [name, setName] = React.useState('');
    const [websiteUrl, setWebsiteUrl] = React.useState('');
    const [subdomain, setSubdomain] = React.useState('');
    const inputNameRef = React.useRef<HTMLInputElement>(null);

    // Logic for signing up
    const [isCreating, setIsCreating] = React.useState(false);
    const [createError, setCreateError] = React.useState<Number | null>(null);

    const createAsync = async (e: any) => {
        if (e) {
            e.preventDefault();
        }
        setCreateError(null);
        setIsCreating(true);
        let error = null;
        let updatedUser = null;
        try {
            const response = await axios.post(
                `${config.apiBaseUrl}/organisation/`,
                {
                    name,
                    websiteUrl,
                    subdomain,
                },
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                }
            );
            updatedUser = response.data;
            window.scrollTo(0, 0);
        } catch (err: any) {
            if (err && err.response && err.response.status) {
                error = err.response.status;
                if (
                    err.response.data != null &&
                    err.response.data ===
                        'User has already been linked to an organisation'
                ) {
                    error = 4091;
                }
            } else {
                console.error(err);
                error = 1;
                // dispatch(createError(err))
            }
            setCreateError(error);
        }
        if (updatedUser != null) {
            // setOrganisation(organisation)
            dispatch(
                userSet({
                    ...updatedUser,
                })
            );
            // setUser({
            //     ...updatedUser,
            //     // organisationId: organisation.id,
            // });

            await navigate(`/account/overview`);
            // if (error == null) {
            //     await navigate('/app/dashboard')
            // }
        } else {
            setIsCreating(false);
        }
    };

    React.useEffect(() => {
        if (inputNameRef.current && isSignedIn) {
            inputNameRef.current.focus();
        }
    }, [isSignedIn]);

    const onSubdomainChange = (e) => {
        setSubdomain(
            e.target.value
                .toLowerCase()
                .replace(/[^\w\s-]/gi, '')
                .replace(/ /g, '')
        );
    };

    const isValidForm =
        name.length > 1 && subdomain.length > 1 && acceptedTerms;

    const renderError = (error: Number | null) => {
        if (error === null) {
            return null;
        }
        // if (error === 4091) {
        //     return <>{localization[language].create.labelAlreadyLinked}</>
        // }
        if (error === 409) {
            return <>{localization[language].create.labelSubdomainInUse}</>;
        }
        return <>{localization[language].create.labelError}</>;
    };

    // if (!isSignedIn) {
    //     return (
    //         <>
    //             <SEO
    //                 title={localization[language].create.title}
    //                 description={localization[language].create.title}
    //             />
    //             <Layout>
    //                 <div className={style.createPage}>
    //                     <h3>{localization[language].create.labelLoading}</h3>
    //                 </div>
    //             </Layout>
    //         </>
    //     )
    // }

    return (
        <>
            {/* <SEO
                title={localization[language].create.title}
                description={localization[language].create.title}
            /> */}
            {/* <Layout> */}
            <div className={style.createPage}>
                <h2>{localization[language].app.createNewOrganisation}</h2>
                <Card className={style.createPageCard}>
                    <CardContent>
                        <form className={style.form} onSubmit={createAsync}>
                            <div className={style.item}>
                                <label className={style.label} htmlFor='name'>
                                    {localization[language].create.labelName}
                                </label>
                                <input
                                    ref={inputNameRef}
                                    type='text'
                                    id='name'
                                    name='name'
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    maxLength={2048}
                                />
                            </div>
                            <div className={style.item}>
                                <label className={style.label} htmlFor='url'>
                                    {localization[language].create.labelWebsite}
                                </label>
                                <input
                                    type='text'
                                    id='url'
                                    name='url'
                                    value={websiteUrl}
                                    placeholder='https://'
                                    onChange={(e) =>
                                        setWebsiteUrl(e.target.value)
                                    }
                                    maxLength={8196}
                                />
                            </div>

                            <div className={style.item}>
                                <label
                                    className={style.label}
                                    htmlFor='subdomain'
                                >
                                    {
                                        localization[language].create
                                            .labelSubdomain
                                    }
                                </label>
                                <Tooltip size='small'>
                                    {
                                        localization[language].create
                                            .labelSubdomainHelp
                                    }
                                </Tooltip>
                                <div className={style.rightContentWrapper}>
                                    <input
                                        type='text'
                                        id='subdomain'
                                        name='subdomain'
                                        value={subdomain}
                                        pattern='^[a-zA-Z0-9]+$'
                                        onChange={onSubdomainChange}
                                        maxLength={100}
                                    />
                                    <div className={style.rightContent}>
                                        .tilkynna.is
                                    </div>
                                </div>
                            </div>

                            <div
                                className={classNames(style.item, style.terms)}
                            >
                                <Checkbox
                                    onChange={(e) =>
                                        setAcceptedTerms(e.target.checked)
                                    }
                                    isChecked={acceptedTerms}
                                    id='terms'
                                    name='terms'
                                    size='small'
                                >
                                    {localization[language].form.readAndAgree}{' '}
                                    <a
                                        href={`/${language}/legal/organisation/terms-of-use/`}
                                        target='_blank'
                                        rel='noreferrer'
                                    >
                                        {localization[language].form.termsOfUse}
                                    </a>
                                    ,{' '}
                                    <a
                                        href={`/${language}/legal/organisation/dpa/`}
                                        target='_blank'
                                        rel='noreferrer'
                                    >
                                        {localization[language].form.dpa}
                                    </a>{' '}
                                    {localization[language].form.and}{' '}
                                    <a
                                        href={`/${language}/legal/organisation/privacy-policy/`}
                                        target='_blank'
                                        rel='noreferrer'
                                    >
                                        {
                                            localization[language].form
                                                .privacyPolicy
                                        }
                                    </a>{' '}
                                    Tilkynna
                                </Checkbox>
                            </div>

                            <p
                                className={classNames({
                                    [style.error]: true,
                                    [style.hasError]:
                                        createError !== null && !isCreating,
                                })}
                            >
                                {renderError(createError)}
                            </p>

                            <input
                                type='submit'
                                value={
                                    isCreating
                                        ? localization[language].register
                                              .labelCreating
                                        : localization[language].password
                                              .labelContinue
                                }
                                className='btn'
                                onClick={createAsync}
                                disabled={!isValidForm || isCreating}
                            />
                        </form>
                    </CardContent>
                </Card>
            </div>
            {/* </Layout> */}
        </>
    );
};

export default CreateOrganisation;
