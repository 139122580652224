import React, { useState } from 'react';
import axios from 'axios';
import {
    Button,
    Card,
    CardActions,
    CardContent,
    CircularProgress,
} from '@mui/material';
import localization from '../../../localization';
import config from '../../../config';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { userSet } from '../../../state/reducers/globalReducer';
import * as style from './index.module.scss';
import { navigate } from 'gatsby';

interface Props {
    language: string;
}

const Invitation = ({ language }: Props) => {
    const user = useAppSelector((state) => state.global.user);
    const isSignedIn = useAppSelector((state) => state.global.isSignedIn);
    const [isAcceptingInvitation, setIsAcceptingInvitation] = useState(false);
    const accessToken = useAppSelector(
        (state) => state.global.auth.accessToken
    );
    const refIsRemoved = React.useRef(false);

    const dispatch = useAppDispatch();

    React.useEffect(() => {
        return () => {
            refIsRemoved.current = true;
        };
    }, []);

    const acceptInvitationAsync = async (org: any) => {
        setIsAcceptingInvitation(true);
        try {
            const response = await axios.put(
                `${config.apiBaseUrl}/organisation/${org.organisationId}/invite/accept`,
                null,
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                }
            );

            const updatedUser = response.data;

            dispatch(
                userSet({
                    ...updatedUser,
                })
            );
        } catch (err) {
            console.log(err);
            setIsAcceptingInvitation(false);
        }

        await navigate(`/account/overview`);
    };

    const deleteLinkedOrganisation = async (org: any) => {
        try {
            const response = await axios.delete(
                `${config.apiBaseUrl}/organisation/${org.organisationId}/link`,
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                }
            );

            const updatedUser = response.data;

            dispatch(
                userSet({
                    ...updatedUser,
                })
            );
        } catch (err) {
            console.log(err);
        }
    };

    if (!isSignedIn) {
        return (
            <div className={style.invitationPage}>
                <h2>{localization[language].invite.invite}</h2>
                <CircularProgress />
            </div>
        );
    }

    return (
        <div className={style.invitationPage}>
            <h2>{localization[language].invite.invite}</h2>
            {user === null && <CircularProgress />}
            {user != null &&
                user.organisations != null &&
                user.organisations
                    .filter((org: any) => org.hasAccepted === false)
                    .map((org: any) => (
                        <Card
                            variant='outlined'
                            className={style.organisationCard}
                            key={org.organisationId}
                        >
                            <CardContent>
                                <h3>{org.organisationName}</h3>
                                <p>
                                    {localization[language].app.labelRole}:{' '}
                                    <span>
                                        <i>
                                            {
                                                localization[language].roles[
                                                    org.role
                                                ]
                                            }
                                        </i>
                                    </span>
                                </p>
                            </CardContent>
                            <CardActions>
                                <Button
                                    onClick={() => acceptInvitationAsync(org)}
                                    disabled={isAcceptingInvitation}
                                >
                                    {localization[language].app.acceptInvite}
                                </Button>
                                <Button
                                    disabled={isAcceptingInvitation}
                                    color='secondary'
                                    style={{ marginLeft: 'auto' }}
                                    onClick={() =>
                                        deleteLinkedOrganisation(org)
                                    }
                                >
                                    {localization[language].app.declineInvite}
                                </Button>
                            </CardActions>
                        </Card>
                    ))}
            {user != null &&
                (user.organisations == null ||
                    user.organisations.filter(
                        (org: any) => org.hasAccepted === false
                    ).length === 0) && (
                    <p>{localization[language].app.noInvitationFound}</p>
                )}
        </div>
    );
};

export default Invitation;
