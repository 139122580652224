import React, { useState } from 'react';
import {
    Button,
    Card,
    CardActionArea,
    CardActions,
    CardContent,
    CircularProgress,
    Paper,
} from '@mui/material';
import localization from '../../../localization';
import { navigate } from 'gatsby-link';
import { useAppSelector } from '../../../hooks';
import * as style from './index.module.scss';
import config from '../../../config';
import axios from 'axios';
import SimpleDate from '../../SimpleDate';

interface Props {
    language: string;
    accessToken: string;
    organisationId: string;
}

const AdminOrganisation = ({
    language,
    accessToken,
    organisationId,
}: Props) => {
    const user = useAppSelector((state) => state.global.user);
    const isSignedIn = useAppSelector((state) => state.global.isSignedIn);
    const refIsRemoved = React.useRef(false);
    const [organisationData, setOrganisationData] = useState<any>(null);

    React.useEffect(() => {
        return () => {
            refIsRemoved.current = true;
        };
    }, []);

    React.useEffect(() => {
        if (user?.policy === 'admin') {
            const getAllOrganisationsAsync = async () => {
                try {
                    // console.log('### Fetching important reports. ', new Date().toString())
                    const response = await axios.get(
                        `${config.apiBaseUrl}/organisation/admin/${organisationId}`,
                        {
                            headers: {
                                Authorization: `Bearer ${accessToken}`,
                            },
                        }
                    );
                    setOrganisationData(response.data);
                    console.log(response.data);
                } catch (err) {
                    console.log(err);
                }
            };

            getAllOrganisationsAsync();
        }
    }, [user?.policy]);

    const resetReports = async (e: any) => {
        if (e) {
            e.preventDefault();
        }
        await axios.post(
            `${config.apiBaseUrl}/organisation/admin/${organisationId}/reports/reset`,
            undefined,
            {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            }
        );
    };

    return (
        <div className={style.overviewPage}>
            <h2>Organisation management</h2>
            {user === null && <CircularProgress />}
            {isSignedIn && user != null && organisationData && (
                <Card variant='outlined' className={style.organisationCard}>
                    <CardContent>
                        <h3>{organisationData.organisation.name}</h3>
                        <a
                            href={`https://${organisationData.organisation.subdomain}.tilkynna.is`}
                        >
                            {organisationData.organisation.subdomain}
                            .tilkynna.is
                        </a>
                        <p>
                            <SimpleDate
                                dateString={
                                    organisationData.organisation.created
                                }
                            />
                        </p>
                    </CardContent>

                    <CardActions>
                        <Button color='primary' onClick={resetReports}>
                            Reset reports
                        </Button>
                    </CardActions>
                </Card>
            )}
        </div>
    );
};

export default AdminOrganisation;
