import React, { useCallback } from 'react';
import axios from 'axios';
import {
    Avatar,
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    CircularProgress,
    IconButton,
} from '@mui/material';
import localization from '../../../localization';
import config from '../../../config';
import { Cancel } from '@mui/icons-material';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { userSet } from '../../../state/reducers/globalReducer';
import * as style from './index.module.scss';

interface Props {
    language: string;
}

const Settings = ({ language }: Props) => {
    const [name, setName] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [isEditingName, setIsEditingName] = React.useState(false);
    const [isSavingName, setIsSavingName] = React.useState(false);
    const [isEditingPassword, setIsEditingPassword] = React.useState(false);
    const [isSavingPassword, setIsSavingPassword] = React.useState(false);
    const [isRemovingOrganisationLink, setIsRemovingOrganisationLink] =
        React.useState(false);
    const [isDisconnectingIcelandAuth, setIsDisconnectingIcelandAuth] =
        React.useState(false);

    const accessToken = useAppSelector(
        (state) => state.global.auth.accessToken
    );
    const isSignedIn = useAppSelector((state) => state.global.isSignedIn);
    const user = useAppSelector((state) => state.global.user);
    const refName = React.useRef();
    const refPassword = React.useRef();
    const refIsRemoved = React.useRef(false);

    const dispatch = useAppDispatch();

    React.useEffect(() => {
        return () => {
            refIsRemoved.current = true;
        };
    }, []);

    const isValidName = name != null && name.length > 1;
    const isValidPassword = password != null && password.length > 4;

    const handleSaveName = async () => {
        setIsSavingName(true);
        const formData = new FormData();
        formData.append('name', name);

        try {
            const data = (
                await axios.put(`${config.apiBaseUrl}/account/name`, formData, {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                })
            ).data;

            if (user != null) {
                dispatch(
                    userSet({
                        ...user,
                        name: data.name,
                    })
                );
            }
        } catch (err) {
            console.log(err);
        }
        setIsSavingName(false);
        setIsEditingName(false);
    };

    const handleSavePassword = async () => {
        setIsSavingPassword(true);
        const formData = new FormData();
        if (password != null) {
            formData.append('password', password);
        }

        try {
            await axios.put(`${config.apiBaseUrl}/account/password`, formData, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
        } catch (err) {
            console.log(err);
        }
        setIsSavingPassword(false);
        setIsEditingPassword(false);
    };

    const deleteLinkedOrganisation = async (org) => {
        setIsRemovingOrganisationLink(true);

        try {
            const response = await axios.delete(
                `${config.apiBaseUrl}/organisation/${org.organisationId}/link`,
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                }
            );

            const updatedUser = response.data;

            dispatch(
                userSet({
                    ...updatedUser,
                })
            );
        } catch (err) {
            console.log(err);
        }
        setIsRemovingOrganisationLink(false);
    };

    React.useEffect(() => {
        if (user != null) {
            setName(user.name);
            if (isEditingName && refName.current != null) {
                refName.current.focus();
            }
        }
    }, [isEditingName, user?.name]);

    React.useEffect(() => {
        if (isEditingPassword && refPassword.current != null) {
            refPassword.current.focus();
        }
    }, [isEditingPassword]);

    const onIcelandAuthStart = (e: any) => {
        if (e && e.preventDefault) {
            e.preventDefault();
        }
        window.open(
            `https://innskraning.island.is/?id=tilkynna&authid=connect-1122`
        );
    };

    const onIcelandAuthDisconnect = (e) => {
        if (e && e.preventDefault) {
            e.preventDefault();
        }

        const icelandDisconnectAsync = async () => {
            setIsDisconnectingIcelandAuth(true);
            try {
                await axios.delete(
                    `${config.apiBaseUrl}/account/connect/icelandauth`,
                    {
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                        },
                    }
                );
                if (user !== null) {
                    dispatch(
                        userSet({
                            ...user,
                            idNumber: null,
                            hasDigitalCertConnection: false,
                        })
                    );
                }
            } catch (err) {
                console.log(err);
            }
            setIsDisconnectingIcelandAuth(false);
        };

        icelandDisconnectAsync();
    };

    if (user == null || !isSignedIn) {
        return (
            <div className={style.settingsPage}>
                <h2>{localization[language].app.labelSettings}</h2>
                <CircularProgress />
            </div>
        );
    }

    return (
        <div className={style.settingsPage}>
            <h2>{localization[language].app.labelSettings}</h2>
            <Card className={style.item} variant='outlined'>
                <CardContent>
                    <label htmlFor='name'>
                        {localization[language].form.labelName}
                    </label>
                    {!isEditingName && (
                        <div className={style.currentSetting}>{user.name}</div>
                    )}
                    {isEditingName && (
                        <>
                            <input
                                ref={refName}
                                type='text'
                                name='name'
                                id='name'
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                readOnly={isSavingName}
                            />
                            <div className={style.actions}>
                                <Button
                                    onClick={handleSaveName}
                                    variant='outlined'
                                    size='small'
                                    disableElevation
                                    style={{
                                        outlineColor: 'rgba(66, 180, 230, .5)',
                                    }}
                                    disabled={isSavingName || !isValidName}
                                >
                                    {localization[language].app.labelSave}
                                </Button>
                            </div>
                        </>
                    )}
                </CardContent>
                <CardActions>
                    {!isEditingName ? (
                        <Button
                            variant='text'
                            onClick={() => setIsEditingName(true)}
                        >
                            {localization[language].app.labelEdit}
                        </Button>
                    ) : (
                        <Button
                            onClick={() => setIsEditingName(false)}
                            variant='text'
                            disabled={isSavingName}
                        >
                            {localization[language].app.labelCancel}
                        </Button>
                    )}
                </CardActions>
            </Card>

            <Card className={style.item} variant='outlined'>
                <CardContent>
                    <label>{localization[language].form.labelEmail}</label>
                    <div className={style.currentSetting}>{user.email}</div>
                </CardContent>
            </Card>

            <Card className={style.item} variant='outlined'>
                <CardContent>
                    <label>
                        {localization[language].app.linkedOrganisations}
                    </label>
                    <div className={style.currentSetting}>
                        {user != null &&
                            user.organisations != null &&
                            user.organisations.map((org: any) => (
                                <Card
                                    key={org.organisationId}
                                    variant='outlined'
                                    className={style.organisationItem}
                                >
                                    <CardHeader
                                        avatar={
                                            <Avatar>
                                                {org.organisationName.substr(
                                                    0,
                                                    1
                                                )}
                                            </Avatar>
                                        }
                                        title={org.organisationName}
                                        subheader={
                                            localization[language].roles[
                                                org.role
                                            ]
                                        }
                                        action={
                                            <IconButton
                                                color='secondary'
                                                aria-label='Delete'
                                                onClick={() =>
                                                    deleteLinkedOrganisation(
                                                        org
                                                    )
                                                }
                                                disabled={
                                                    isRemovingOrganisationLink
                                                }
                                            >
                                                <Cancel />
                                            </IconButton>
                                        }
                                    />
                                </Card>
                            ))}
                    </div>
                </CardContent>
            </Card>

            <Card className={style.item} variant='outlined'>
                <CardContent>
                    <label htmlFor='password'>
                        {localization[language].register.labelPassword}
                    </label>

                    {isEditingPassword && (
                        <>
                            <input
                                ref={refPassword}
                                type='password'
                                name='password'
                                id='password'
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                readOnly={isSavingPassword}
                            />
                            <div className={style.actions}>
                                <Button
                                    onClick={handleSavePassword}
                                    variant='outlined'
                                    size='small'
                                    disableElevation
                                    style={{
                                        outlineColor: 'rgba(66, 180, 230, .5)',
                                    }}
                                    disabled={
                                        isSavingPassword || !isValidPassword
                                    }
                                >
                                    {localization[language].app.labelSave}
                                </Button>
                                <Button
                                    onClick={() => setIsEditingPassword(false)}
                                    variant='outlined'
                                    size='small'
                                    disabled={isSavingPassword}
                                >
                                    {localization[language].app.labelCancel}
                                </Button>
                            </div>
                        </>
                    )}
                </CardContent>
                {!isEditingPassword && (
                    <CardActions>
                        <Button
                            onClick={() => setIsEditingPassword(true)}
                            disabled={isSavingPassword}
                        >
                            {localization[language].app.labelEdit}
                        </Button>
                    </CardActions>
                )}
            </Card>

            <Card className={style.item} variant='outlined'>
                <CardContent>
                    <label>{localization[language].icelandauth.title}</label>
                    {user.hasDigitalCertConnection && (
                        <small>
                            <strong>
                                {
                                    localization[language].form
                                        .labelPersonalIdNumber
                                }
                            </strong>
                            : {user.idNumber}
                        </small>
                    )}
                </CardContent>
                <CardActions>
                    {user.hasDigitalCertConnection ? (
                        <Button
                            onClick={onIcelandAuthDisconnect}
                            disabled={isDisconnectingIcelandAuth}
                        >
                            {localization[language].icelandauth.disconnect}
                        </Button>
                    ) : (
                        <Button onClick={onIcelandAuthStart}>
                            {localization[language].icelandauth.connect}
                        </Button>
                    )}
                </CardActions>
            </Card>
        </div>
    );
};

export default Settings;
