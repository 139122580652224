import React, { useCallback } from 'react';
import { navigate } from 'gatsby';
import { Router } from '@reach/router';
import axios from 'axios';
import { styled } from '@mui/material/styles';
import {
    AppBar as MuiAppBar,
    Toolbar,
    IconButton,
    Typography,
    Drawer as MuiDrawer,
    List,
    Menu,
    MenuItem,
    ListItemIcon,
    ListItemText,
    Divider,
    Box,
    ListItemButton,
} from '@mui/material';

import {
    Menu as MenuIcon,
    Dashboard as DashboardIcon,
    Settings as SettingsIcon,
    AccountCircle,
    Person,
    ExitToApp,
    Language,
    HowToReg,
    AdminPanelSettings,
} from '@mui/icons-material';
import SEO from '../seo';
import config from '../../config';
import localization from '../../localization';

import Overview from './Overview';
import Settings from './Settings';
import CreateOrganisation from './CreateOrganisation';
import Invitation from './Invitation';
import ConnectDigitalCert from './ConnectDigitialCert';
import { useAppDispatch, useAppSelector } from '../../hooks';
import * as style from './index.module.scss';
import {
    languageSet,
    organisationSet,
    userSet,
} from '../../state/reducers/globalReducer';
import AdminOverview from './AdminOverview';
import AdminOrganisation from './AdminOrganisation';

const drawerWidth = 220;
const isBrowser = typeof window !== 'undefined';

const openedMixin = (theme: any) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme: any) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(9)} + 1px)`,
    },

    // width: theme.spacing(7) + 1,
    // [theme.breakpoints.up('sm')]: {
    //     width: theme.spacing(9) + 1,
    // },
});

const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
        ...closedMixin(theme),
        '& .MuiDrawer-paper': closedMixin(theme),
    }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    backgroundColor: '#0277bc',
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        // marginLeft: drawerWidth,
        // backgroundColor: '#0277bc',
        // width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Account = ({ location }) => {
    const isReady = useAppSelector((state) => state.global.isReady);
    const isSignedIn = useAppSelector((state) => state.global.isSignedIn);
    const isSigningIn = useAppSelector((state) => state.global.isSigningIn);
    const user = useAppSelector((state) => state.global.user);
    const language = useAppSelector((state) => state.global.language);
    const organisation = useAppSelector((state) => state.global.organisation);
    const accessToken = useAppSelector(
        (state) => state.global.auth.accessToken
    );
    const dispatch = useAppDispatch();

    const setOrganisation = useCallback(
        (organisation) => {
            dispatch(organisationSet(organisation));
        },
        [dispatch]
    );

    const setLanguage = useCallback(
        (language) => dispatch(languageSet(language)),
        [dispatch]
    );

    const setUser = useCallback((user) => dispatch(userSet(user)), [dispatch]);

    const [open, setOpen] = React.useState(
        isBrowser && window.innerWidth > 700
    );
    const [anchorElLanguage, setAnchorElLanguage] = React.useState(null);
    const [anchorElAccount, setAnchorElAccount] = React.useState(null);
    const pathName = location.pathname;

    // Set current organisation to null since we are on the account page
    React.useEffect(() => {
        if (organisation != null) {
            setOrganisation(null);
        }
    }, [organisation]);

    const changeLanguageAsync = async (newLanguage: string) => {
        handleClose();
        setLanguage(newLanguage);

        // if (isReportLink) {
        //     return
        // }

        const formData = new FormData();
        formData.append('language', newLanguage);

        try {
            const data = (
                await axios.put(
                    `${config.apiBaseUrl}/account/language`,
                    formData,
                    {
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                        },
                    }
                )
            ).data;
            setUser({
                ...user,
                language: data.language,
            });
        } catch (err) {
            console.log(err);
        }

        // try {
        //     await axios.get(
        //         `${config.apiBaseUrl}/account/migrateorgs`,
        //         {
        //             headers: {
        //                 Authorization: `Bearer ${accessToken}`,
        //             },
        //         },
        //     )
        // } catch (err) {
        //     console.log(err)
        // }
    };

    if (!isBrowser) {
        return null;
    }

    if (!isSignedIn && !isSigningIn && isReady) {
        if (location.search && location.search.indexOf('jwt=') > -1) {
            navigate(
                `/${language}/signin?redirect=${encodeURIComponent(
                    location.pathname + location.search
                )}`
            );
        } else {
            navigate(
                `/${language}/signin?redirect=${encodeURIComponent(
                    location.pathname
                )}`
            );
        }

        return null;
    }

    const toggleDrawer = () => {
        setOpen(!open);
    };

    const handleAccountMenu = (event: any) => {
        setAnchorElAccount(event.currentTarget);
        setAnchorElLanguage(null);
        // setAnchorElNotifications(null)
    };

    const handleLanguageMenu = (event: any) => {
        setAnchorElLanguage(event.currentTarget);
        setAnchorElAccount(null);
        // setAnchorElNotifications(null)
    };

    // const handleNotificationsMenu = (event) => {
    //     setAnchorElNotifications(event.currentTarget)
    //     setAnchorElAccount(null)
    //     setAnchorElLanguage(null)
    // }

    const handleClose = () => {
        setAnchorElLanguage(null);
        setAnchorElAccount(null);
        // setAnchorElNotifications(null)
    };

    const handleSignOut = () => {
        handleClose();
        navigate(`/${language}/signout`);
    };

    // Disable SSR for the app pages
    if (!isBrowser) {
        return null;
    }

    const renderMenuItems = () => {
        return (
            <>
                <DrawerHeader />
                <List>
                    <ListItemButton
                        selected={pathName.startsWith('/account/overview')}
                        onClick={() => navigate('/account/overview')}
                    >
                        <ListItemIcon>
                            <DashboardIcon />
                        </ListItemIcon>
                        <ListItemText
                            primary={localization[language].app.overview}
                        />
                    </ListItemButton>
                    <ListItemButton
                        selected={pathName.startsWith('/account/invitation')}
                        onClick={() => navigate('/account/invitation')}
                    >
                        <ListItemIcon>
                            <HowToReg />
                        </ListItemIcon>
                        <ListItemText
                            primary={localization[language].invite.invite}
                        />
                    </ListItemButton>
                </List>
                <Divider />
                <List>
                    <ListItemButton
                        selected={pathName === '/account/settings'}
                        onClick={() => navigate('/account/settings')}
                    >
                        <ListItemIcon>
                            <SettingsIcon />
                        </ListItemIcon>
                        <ListItemText
                            primary={localization[language].app.labelSettings}
                        />
                    </ListItemButton>
                </List>
                <Divider />
                {user?.policy === 'admin' && (
                    <>
                        <List>
                            <ListItemButton
                                selected={pathName === '/account/admin'}
                                onClick={() => navigate('/account/admin')}
                            >
                                <ListItemIcon>
                                    <AdminPanelSettings />
                                </ListItemIcon>
                                <ListItemText primary={'Admin'} />
                            </ListItemButton>
                        </List>
                        <Divider />
                    </>
                )}
                <List>
                    <ListItemButton
                        onClick={() => navigate(`/${language}/signout`)}
                    >
                        <ListItemIcon>
                            <ExitToApp />
                        </ListItemIcon>
                        <ListItemText
                            primary={localization[language].app.labelSignOut}
                        />
                    </ListItemButton>
                </List>
            </>
        );
    };

    return (
        <>
            <SEO title={organisation != null ? organisation.name : ''} />
            <Box sx={{ display: 'flex' }}>
                {/* <Root className={classes.root}> */}
                <AppBar open={open} position='fixed'>
                    <Toolbar>
                        <IconButton
                            color='inherit'
                            aria-label='open drawer'
                            onClick={toggleDrawer}
                            edge='start'
                        >
                            <MenuIcon />
                        </IconButton>
                        <Typography
                            variant='h6'
                            noWrap
                            // className={classes.title}
                        >
                            {localization[language].app.labelAccount}
                        </Typography>
                        <Box sx={{ flexGrow: 1 }} />
                        <Box>
                            <IconButton
                                aria-label='change language'
                                aria-controls='menu-language'
                                aria-haspopup='true'
                                onClick={handleLanguageMenu}
                                color='inherit'
                            >
                                <Language />
                            </IconButton>

                            <Menu
                                id='menu-language'
                                anchorEl={anchorElLanguage}
                                transformOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                }}
                                open={Boolean(anchorElLanguage)}
                                onClose={handleClose}
                            >
                                <MenuItem disabled style={{ fontSize: '1em' }}>
                                    {language === 'is' ? 'Íslenska' : 'English'}
                                </MenuItem>
                                {language !== 'is' && (
                                    <MenuItem
                                        onClick={() => {
                                            changeLanguageAsync('is');
                                        }}
                                    >
                                        Íslenska
                                    </MenuItem>
                                )}
                                {language !== 'en' && (
                                    <MenuItem
                                        onClick={() => {
                                            changeLanguageAsync('en');
                                        }}
                                    >
                                        English
                                    </MenuItem>
                                )}
                            </Menu>

                            <Menu
                                id='menu-appbar'
                                anchorEl={anchorElAccount}
                                transformOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                }}
                                open={Boolean(anchorElAccount)}
                                onClose={handleClose}
                            >
                                <MenuItem disabled style={{ fontSize: '1em' }}>
                                    {user?.email || ''}
                                </MenuItem>

                                <MenuItem
                                    onClick={() => {
                                        navigate(`/account/overview`);
                                        handleClose();
                                    }}
                                >
                                    <ListItemIcon style={{ minWidth: 36 }}>
                                        <Person fontSize='small' />
                                    </ListItemIcon>
                                    {localization[language].app.labelAccount}
                                </MenuItem>
                                <MenuItem onClick={handleSignOut}>
                                    <ListItemIcon style={{ minWidth: 36 }}>
                                        <ExitToApp fontSize='small' />
                                    </ListItemIcon>
                                    {localization[language].app.labelSignOut}
                                </MenuItem>
                            </Menu>

                            <IconButton
                                aria-label='account of current user'
                                aria-controls='menu-appbar'
                                aria-haspopup='true'
                                onClick={handleAccountMenu}
                                color='inherit'
                            >
                                <AccountCircle />
                            </IconButton>
                        </Box>
                    </Toolbar>
                </AppBar>
                <Drawer variant='permanent' open={open}>
                    <div className={style.topDrawer}>{renderMenuItems()}</div>
                    {/* <div className={style.bottomDrawer}>
                            <List>
                                <ListItemButton
                                    onClick={handleSignOut}
                                >
                                    <ListItemIcon><ExitToApp /></ListItemIcon>
                                    <ListItemText primary={localization[language].app.labelSignOut} />
                                </ListItemButton>
                            </List>
                        </div> */}
                </Drawer>

                <Box
                    component='main'
                    sx={{
                        flexGrow: 1,
                        p: 3,
                        backgroundColor: '#f4f4f4',
                        minHeight: '100vh',
                    }}
                >
                    <DrawerHeader />
                    <Router>
                        <Overview
                            default
                            path='/account/overview/'
                            accessToken={accessToken}
                            language={language}
                        />
                        <Invitation
                            path='/account/invitation/'
                            accessToken={accessToken}
                            language={language}
                        />
                        <CreateOrganisation
                            path='/account/create-organisation/'
                            accessToken={accessToken}
                            language={language}
                        />
                        <Settings
                            path='/account/settings/'
                            accessToken={accessToken}
                            language={language}
                        />
                        <ConnectDigitalCert
                            path='/account/auth/'
                            accessToken={accessToken}
                            language={language}
                            location={location}
                        />
                        <AdminOrganisation
                            path='/account/admin/:organisationId'
                            accessToken={accessToken}
                            language={language}
                        />
                        <AdminOverview
                            path='/account/admin/'
                            accessToken={accessToken}
                            language={language}
                        />
                    </Router>
                </Box>
                {/* </Root> */}
            </Box>
        </>
    );
};

export default Account;
