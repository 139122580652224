// extracted by mini-css-extract-plugin
export var createPage = "index-module--createPage--zn3di";
export var createPageCard = "index-module--createPageCard--Dob3T";
export var error = "index-module--error--gn9fE";
export var form = "index-module--form--b62ML";
export var fullSize = "index-module--fullSize--vePBL";
export var hasError = "index-module--hasError--CDKC2";
export var item = "index-module--item--O32YD";
export var label = "index-module--label--JpFPS";
export var rightContent = "index-module--rightContent--sE9yT";
export var rightContentWrapper = "index-module--rightContentWrapper--TAw6C";
export var terms = "index-module--terms--acAZR";