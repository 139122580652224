import React, { useState } from 'react';
import {
    Button,
    Card,
    CardActionArea,
    CardActions,
    CardContent,
    CircularProgress,
} from '@mui/material';
import localization from '../../../localization';
import { navigate } from 'gatsby-link';
import { useAppSelector } from '../../../hooks';
import * as style from './index.module.scss';
import config from '../../../config';
import axios from 'axios';
import SimpleDate from '../../SimpleDate';

interface Props {
    language: string;
    accessToken: string;
}

const AdminOverview = ({ language, accessToken }: Props) => {
    const user = useAppSelector((state) => state.global.user);
    const isSignedIn = useAppSelector((state) => state.global.isSignedIn);
    const refIsRemoved = React.useRef(false);
    const [organisations, setOrganisations] = useState([]);

    React.useEffect(() => {
        return () => {
            refIsRemoved.current = true;
        };
    }, []);

    React.useEffect(() => {
        if (user?.policy === 'admin') {
            const getAllOrganisationsAsync = async () => {
                try {
                    // console.log('### Fetching important reports. ', new Date().toString())
                    const response = await axios.get(
                        `${config.apiBaseUrl}/organisation/admin/all`,
                        {
                            headers: {
                                Authorization: `Bearer ${accessToken}`,
                            },
                        }
                    );
                    setOrganisations(response.data.organisations);
                } catch (err) {
                    console.log(err);
                }
            };

            getAllOrganisationsAsync();
        }
    }, [user?.policy]);

    const openOrg = async (org: any) => {
        await navigate(`/account/admin/${org.id}/`);
    };

    return (
        <div className={style.overviewPage}>
            <h2>{localization[language].app.organisations}</h2>
            {user === null && <CircularProgress />}
            {isSignedIn &&
                user != null &&
                organisations.map((org: any) => (
                    <Card
                        variant='outlined'
                        className={style.organisationCard}
                        key={org.id}
                    >
                        <CardActionArea
                            href={`/account/admin/${org.id}/`}
                            onClick={() => openOrg(org)}
                            color='primary'
                        >
                            <CardContent>
                                <h3>{org.name}</h3>
                                <p>
                                    <SimpleDate dateString={org.created} />
                                </p>
                            </CardContent>
                        </CardActionArea>
                        <CardActions>
                            <Button
                                color='primary'
                                onClick={() => openOrg(org)}
                            >
                                {localization[language].app.labelOpen}
                            </Button>
                        </CardActions>
                    </Card>
                ))}
        </div>
    );
};

export default AdminOverview;
