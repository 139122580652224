import React from 'react'
import Account from '../components/Account'

import { ThemeProvider, createTheme } from '@mui/material/styles';



const theme = createTheme({
    palette: {
        primary: {
            dark: '#004c8b',
            main: '#0277bc',
            light: '#58a5ef',
        },
        secondary: {
            dark: 'rgb(235, 90, 70)',
            main: 'rgb(235, 90, 70)',
            light: 'rgb(235, 90, 70)',
        }
    },
})

const AccountPage = ({ location }) => {
    return (
        <ThemeProvider theme={theme}>
            <Account
                location={location}
            // time={new Date().getTime()}
            />
        </ThemeProvider>
    )
}

export default AccountPage
