import React from 'react';
import queryString from 'query-string';
import axios from 'axios';
import {
    Button,
    Card,
    CardActions,
    CardContent,
    CircularProgress,
} from '@mui/material';
import localization from '../../../localization';
import config from '../../../config';
import { navigate } from 'gatsby';
import classNames from 'classnames';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import * as styles from './index.module.scss';
import { userSet } from '../../../state/reducers/globalReducer';

interface Props {
    language: string;
    location: any;
}

const ConnectDigitalCert = ({ language, location }: Props) => {
    const accessToken = useAppSelector(
        (state) => state.global.auth.accessToken
    );
    const isSignedIn = useAppSelector((state) => state.global.isSignedIn);
    const user = useAppSelector((state) => state.global.user);
    const dispatch = useAppDispatch();
    const [isFetchingIcelandAuth, setIsFetchingIcelandAuth] =
        React.useState(false);
    const [icelandAuth, setIcelandAuth] = React.useState<null | any>(null);
    const [errorCode, setErrorCode] = React.useState(0);

    const [isConfirmingConnection, setIsConfirmingConnection] =
        React.useState(false);

    React.useEffect(() => {
        const params = new queryString.parse(location.search);
        if (params && params.jwt) {
            const getIcelandAuthAsync = async () => {
                setIsFetchingIcelandAuth(true);
                try {
                    const response = await axios.get(
                        `${config.apiBaseUrl}/account/icelandauth`,
                        {
                            headers: {
                                Authorization: `Bearer ${params.jwt}`,
                            },
                        }
                    );

                    setIcelandAuth({
                        ...response.data,
                        jwt: params.jwt,
                    });
                } catch (err) {
                    console.log(err);
                }
                setIsFetchingIcelandAuth(false);
            };

            getIcelandAuthAsync();
        }
    }, [location]);

    const onConfirm = (e) => {
        if (e && e.preventDefault) {
            e.preventDefault();
        }

        setErrorCode(0);

        const jwt = icelandAuth.jwt;
        const confirmIcelandAuthAsync = async () => {
            setIsConfirmingConnection(true);
            try {
                const response = await axios.post(
                    `${config.apiBaseUrl}/account/connect/icelandauth?jwt=${jwt}`,
                    { icelandAuth: jwt },
                    {
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                        },
                    }
                );

                if (user !== null) {
                    dispatch(
                        userSet({
                            ...user,
                            idNumber: response.data.user.idNumber,
                            hasDigitalCertConnection: true,
                        })
                    );
                }

                await navigate(`/account/settings`);
            } catch (err: any) {
                console.log(err);
                if (err && err.response && err.response.status) {
                    setErrorCode(err.response.status);
                }
                setIsConfirmingConnection(false);
            }
        };

        confirmIcelandAuthAsync();
    };

    const renderError = () => {
        if (errorCode === 409) {
            return <>{localization[language].icelandauth.alreadyConnected}</>;
        }
        return null;
    };

    if (user == null || !isSignedIn) {
        return (
            <div className={styles.connectDigitalCertPage}>
                <h2>{localization[language].icelandauth.title}</h2>
                <CircularProgress />
            </div>
        );
    }

    return (
        <div className={styles.connectDigitalCertPage}>
            <h2>{localization[language].icelandauth.title}</h2>

            <Card className={styles.item} variant='outlined'>
                <CardContent>
                    <label>{localization[language].icelandauth.enabled}</label>
                    {isFetchingIcelandAuth && <CircularProgress />}

                    {icelandAuth && (
                        <div className={styles.icelandAuthUser}>
                            <p>
                                <strong>
                                    {localization[language].app.labelName}
                                </strong>
                                : {icelandAuth.name}
                            </p>
                            <p>
                                <strong>
                                    {
                                        localization[language].form
                                            .labelPersonalIdNumber
                                    }
                                </strong>
                                : {icelandAuth.ssn}
                            </p>
                            {errorCode > 0 && (
                                <p
                                    className={classNames({
                                        [styles.error]: true,
                                        [styles.hasError]: errorCode !== 0,
                                    })}
                                >
                                    {renderError()}
                                </p>
                            )}
                        </div>
                    )}
                </CardContent>
                <CardActions>
                    <Button
                        onClick={onConfirm}
                        variant='contained'
                        size='medium'
                        disabled={
                            isFetchingIcelandAuth ||
                            icelandAuth === null ||
                            isConfirmingConnection
                        }
                    >
                        {localization[language].icelandauth.confirm}
                    </Button>
                </CardActions>
            </Card>
        </div>
    );
};

export default ConnectDigitalCert;
